import React from 'react';

const CTA = () => (
  <div className="cta">
    <div className="cta__text font-large" tabIndex="0">Got an awesome project for us?</div>
    <div className="cta__text">
      <a className="font-display" target="_self" href="/contact/">Let's talk</a>
    </div>
  </div>
);

export default CTA;
