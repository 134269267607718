import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Loadable from 'react-loadable';

import Header from './Header';
import CTA from './CTA';
import '../assets/scss/main.scss';

require('prismjs/themes/prism-okaidia.css');

const pagesWithoutCTA = [
  '/contact/',
  '/',
  '/careers/react-native/',
  '/careers/automation-qa/',
  '/careers/full-stack',
  '/careers/architect/',
  '/careers/project-manager/',
  '/careers/devops-engineer/'
];

const Loading = () => <h3>Loading...</h3>;

const FooterComponent = Loadable({
  loader: () => import('./Footer'),
  loading: Loading
});

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisible: false,
    };
  }

  componentDidMount() {
    return setTimeout(() => {
      const scriptHubspot = document.createElement('script');
      scriptHubspot.type = 'text/javascript';
      scriptHubspot.id = 'hs-script-loader';
      scriptHubspot.async = true;
      scriptHubspot.defer = true;
      scriptHubspot.src = '//js.hs-scripts.com/6671562.js';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(scriptHubspot, x);
    }, 3000);
  }

  handleToggleMenu = () => {
    const { isMenuVisible } = this.state;

    this.setState({
      isMenuVisible: !isMenuVisible,
    });
  };

  render() {
    const { children, location: { pathname } } = this.props;
    const { isMenuVisible } = this.state;

    return (
      <div className={`body ${isMenuVisible ? 'is-menu-visible' : ''}`}>
        <Helmet>
          <link rel="stylesheet preload" />
        </Helmet>
        <div id="wrapper">
          <Header onToggleMenu={this.handleToggleMenu} />
          {children}
        </div>
        { pathname === '/blog/' ? null
          : (
            <div id="wrapper-footer">
              <div className="footer">
                {!pagesWithoutCTA.includes(pathname) ? <CTA /> : null}
                <FooterComponent />
              </div>
            </div>
          )}
      </div>
    );
  }
}

Template.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({}).isRequired,
};

Template.defaultProps = {
  children: null,
};

export default Template;
