import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import BlankA from './BlankHyperlink';

export default class Button extends Component {
  renderButtonType = (type) => {
    const { goTo, title, icon, classNames, internalLink } = this.props;

    switch (type) {
      case 'primary-dark':
        return <Link to={goTo} className="button-primary-dark font-button-bold">{title}</Link>;
      case 'primary-dark-blank':
        return <BlankA href={goTo} className="button-primary-dark font-button-bold">{title}</BlankA>;
      case 'primary-large-dark':
        return (
          <Link
            to={goTo}
            className={classnames('button-primary-large-dark font-button-large-bold', classNames)}
          >
            {title}
          </Link>
        );
      case 'primary-light':
        return (
          <BlankA href={goTo} className="button-primary-light font-button-bold">
            {title}
          </BlankA>
        );
      case 'primary-large-light':
        return internalLink === true ? (
          <Link
            to={goTo}
            className={classnames('button-primary-large-light font-button-large-bold', classNames)}
          >
            {title}
          </Link>
        ) : (
          <BlankA href={goTo} className={classnames('button-primary-large-light font-button-large-bold', classNames)}>
            {title}
          </BlankA>
        );
      case 'primary-large-light-link':
        return (
          <Link to={goTo} className={classnames('button-primary-large-light font-button-large-bold', classNames)}>
            {title}
          </Link>
        );
      case 'primary-large-dark-link':
        return <Link to={goTo} className="button-primary-large-dark font-button-large-bold">{title}</Link>;
      case 'primary-large-light-icon':
        return (
          <BlankA
            href={goTo}
            className={classnames('button-primary-large-light-icon font-button-large-bold icon', icon, classNames)}
          >
            &nbsp;
            {title}
          </BlankA>
        );
      case 'primary-large-light-icon-2':
        return (
          <BlankA
            href={goTo}
            className={classnames('button-primary-large-light-icon font-button-large-bold icon', classNames)}
          >
              &nbsp;
            <i className={icon} />
            {title}
          </BlankA>
        );
      case 'primary-large-dark-icon':
        return (
          <BlankA
            href={goTo}
            className={classnames('button-primary-dark-icon font-button-large-bold', icon)}
          >
            &nbsp;
            {title}
          </BlankA>
        );
      case 'secondary':
        return <Link to={goTo} className="button-secondary-default font-button-bold">{title}</Link>;
      case 'secondary-large':
        return <Link to={goTo} className="button-secondary font-button-large-bold">{title}</Link>;
      case 'secondary-large-icon':
        return (
          <Link to={goTo} className={classnames('button-secondary font-button-large-bold', classNames)}>
            <i className={icon} />
            &nbsp;
            {title}
          </Link>
        );
      default:
        return null;
    }
  };

  render() {
    const { type } = this.props;

    return this.renderButtonType(type);
  }
}

Button.propTypes = {
  goTo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
  classNames: PropTypes.string,
  internalLink: PropTypes.bool
};

Button.defaultProps = {
  icon: '',
  classNames: '',
  internalLink: false
};
