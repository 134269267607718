import React, { Component } from 'react';
import { Link } from 'gatsby';

import MobileNav from './MobileNav';
import { Box } from '../Layout';
import Logo from '../../assets/images/svg/mcro-unicorn-logo.svg';
import HamburgerMenu from './HamburgerMenu';
import Button from '../Button';
import angleRight from '../../assets/images/svg/angle-right.svg';
import caretUp from '../../assets/images/svg/caret-up.svg';

const classesAtTop = 'fullNav main__inner';
const classesScrolled = 'fullNav-scroll fullNav main__inner';
const classesAtTopMobile = 'mobileNav';
const classesScrolledMobile = 'mobileNav mobileNav-scroll';

const submenus = [{
  name: 'Services Overview',
  path: ''
}, {
  name: 'Web Development',
  path: 'web-development/'
}, {
  name: 'App Development',
  path: 'app-development/'
}, {
  name: 'Ideation & Product Strategy',
  path: 'ideation-and-product-strategy/'
}, {
  name: 'UX/UI Design',
  path: 'ux-ui-design/'
}, {
  name: 'Cloud Infrastructure',
  path: 'cloud-infrastructure/'
}];

class Navigation extends Component {
  constructor(props) {
    super(props);

    let defaultIsWhite = false;
    let startClassesFullNav = classesAtTop;
    let startClassesFullMobile = classesAtTopMobile;

    if (typeof window !== 'undefined') {
      if (window.scrollY > 0) {
        startClassesFullNav = classesScrolled;
        startClassesFullMobile = classesScrolledMobile;
        defaultIsWhite = true;
      }
    }

    this.state = {
      isWhite: defaultIsWhite,
      open: false,
      classesFullNav: startClassesFullNav,
      classesMobileNav: startClassesFullMobile,
      showServicesMenu: false
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { isWhite } = this.state;
    if (isWhite === false && window.scrollY > 0) {
      // was transparent
      this.setState({
        isWhite: true,
        classesFullNav: classesScrolled,
        classesMobileNav: classesScrolledMobile
      });
    } else if (isWhite === true && window.scrollY === 0) {
      // was white
      this.setState({
        isWhite: false,
        classesFullNav: classesAtTop,
        classesMobileNav: classesAtTopMobile
      });
    }
  }

  closeNav = () => {
    this.setState({
      open: false,
    });
  };

  toggleNavigation = () => {
    const { open } = this.state;

    this.setState({
      open: !open,
      showServicesMenu: false
    });
  };

  toggleShowServicesMenu = () => {
    const { showServicesMenu } = this.state;

    this.setState({
      showServicesMenu: !showServicesMenu
    });
  }

  renderDropdown = () => {
    const { showServicesMenu } = this.state;

    const className = showServicesMenu ? 'dropdown dropdown-show' : 'dropdown dropdown-hide';

    return (
      <div className={className}>
        {submenus.map((submenu, index) => (
          <div key={`item-${index}`} className="menu-items">
            <Link to={`/services/${submenu.path}`}>{submenu.name}</Link>
          </div>
        ))}
      </div>
    );
  };

  renderMobileNavigation = () => (
    <ul>
      <li>
        <div
          onClick={this.closeNav}
          onKeyPress={this.closeNav}
        >
          <Link to="/">Home</Link>
        </div>
      </li>
      <li>
        <div
          onClick={this.closeNav}
          onKeyPress={this.closeNav}
        >
          <Link to="/about-us/">About Us</Link>
        </div>
      </li>
      <li>
        <div
          onClick={this.closeNav}
          onKeyPress={this.closeNav}
        >
          <Link to="/our-work/">Our Work</Link>
        </div>
      </li>
      <li>
        <div
          onClick={this.toggleShowServicesMenu}
          onKeyPress={this.toggleShowServicesMenu}
        >
          <div>
            Services
            <img src={angleRight} alt="angle" className="arrow-right" />
          </div>
        </div>
      </li>
      <li>
        <div
          onClick={this.closeNav}
          onKeyPress={this.closeNav}
        >
          <Link to="/resources/">Resources</Link>
        </div>
      </li>
      <li style={{ display: 'none' }}>
        <div
          onClick={this.closeNav}
          onKeyPress={this.closeNav}
        >
          <Link to="/our-meetups/">Our Meetups</Link>
        </div>
      </li>
      <li>
        <div
          onClick={this.closeNav}
          onKeyPress={this.closeNav}
        >
          <Link to="/careers/">Careers</Link>
        </div>
      </li>
      <li>
        <div
          onClick={this.closeNav}
          onKeyPress={this.closeNav}
        >
          <Link to="/contact/">Contact</Link>
        </div>
      </li>
      <li>
        <div
          onClick={this.closeNav}
          onKeyPress={this.closeNav}
        >
          <Link to="/blog/">Blog</Link>
        </div>
      </li>
    </ul>
  );

  renderServicesMobileNavigation = () => (
    <ul>
      {
        submenus.map((item, index) => (
          <li key={`item_${index}`}>
            <div
              onClick={this.closeNav}
              onKeyPress={this.closeNav}
            >
              <Link to={`/services/${item.path}`}>{item.name}</Link>
            </div>
          </li>
        ))
      }
    </ul>
  );

  render() {
    const { open, classesFullNav, classesMobileNav, showServicesMenu } = this.state;

    const caretClassName = showServicesMenu ? 'caret-up' : 'caret-down';

    return (
      <nav>
        <Box className={classesFullNav}>
          <div className="nav-wrapper">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="MCRO" title="MCRO" />
              </Link>
            </div>
            <ul className="manus">
              <li><Link activeClassName="selected" to="/">Home</Link></li>
              <li><Link activeClassName="selected" to="/about-us/">About Us</Link></li>
              <li><Link activeClassName="selected" to="/our-work/">Our Work</Link></li>
              <li className="menu-items">
                <>
                  <div
                    className="dropdown-opener"
                    onClick={this.toggleShowServicesMenu}
                    onBlur={this.toggleShowServicesMenu}
                  >
                    Services
                    <img src={caretUp} alt="caret-up" className={caretClassName} />
                  </div>
                  {this.renderDropdown()}
                </>
              </li>
              <li><Link activeClassName="selected" to="/resources/">Resources</Link></li>
              <li style={{ display: 'none' }}>
                <Link activeClassName="selected" to="/our-meetups/">Our Meetups</Link>
              </li>
              <li><Link activeClassName="selected" to="/careers/">Careers</Link></li>
              <li><Link activeClassName="selected" to="/blog/">Blog</Link></li>
            </ul>
            <div className="getInTouch">
              <Button goTo="/contact/" title="Get in Touch" type="primary-large-dark-link" />
            </div>
          </div>
        </Box>
        <Box className={classesMobileNav} px={[2, 3, 4]}>
          <ul>
            <li>
              {
                showServicesMenu
                  ? (
                    <div
                      onClick={this.toggleShowServicesMenu}
                      onKeyPress={this.toggleShowServicesMenu}
                    >
                      <img src={angleRight} alt="angle" className="arrow-left" />
                    </div>
                  ) : (
                    <Link to="/" className="logo">
                      <img src={Logo} alt="MCRO" title="MCRO" width="75" height="16" />
                    </Link>
                  )
              }
            </li>
            <li>
              <div
                className="hamburger-menu"
                aria-label="hamburger-menu"
                onClick={this.toggleNavigation}
                role="button"
                tabIndex="0"
                onKeyPress={this.toggleNavigation}
              >
                <HamburgerMenu
                  isOpen={open}
                  width={28}
                  height={15}
                  strokeWidth={1}
                  rotate={0}
                  color="black"
                  borderRadius={0}
                  animationDuration={0.5}
                />
              </div>
            </li>
          </ul>
        </Box>
        <MobileNav open={open} closeNav={this.closeNav}>
          {showServicesMenu ? this.renderServicesMobileNavigation() : this.renderMobileNavigation()}
        </MobileNav>
      </nav>
    );
  }
}

export default Navigation;
